import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import moment from "moment";
import { Modal } from "antd";
import { useState } from "react";

export default function useShipments() {
  const [shipmentData, setShipmentData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
    message: "Cargando...",
    size: 10,
    page: 1,
    totalResults: 0,
    totalPages: 0,
  });

  const [singleShipmentData, setSingleShipmentData] = useState({
    file: null,
    standAlone: false,
    statusCode: 0,
    message: "Cargando...",
    status: "loading",
    telemetryTypeCode: null,
  });

  const [telemetryInfo, setTelemetryInfo] = useState({
    warningsImpact: 0,
    warningsOpen: 0,
    warningsTemp: 0,
    statusCode: 0,
  });

  const [alertsListInfo, setAlertsListInfo] = useState({
    tempMax: 0,
    tempMin: 0,
    alertsList: [],
    statusCode: 0,
  });

  const [chartsInfo, setChartsInfo] = useState({
    telemetryInfo: [],
    tempMax: 0,
    tempMin: 0,
    statusCode: 0,
  });

  const [eventsInfo, setEventsInfo] = useState({
    steps: [],
    current: 0,
    statusCode: 0,
    message: "",
  });

  const baseSteps = [
    {
      code: ["A090000V"],
      name: "Pre-registro",
    },
    {
      code: ["A010000V", "P100000V", "P040000V"],
      name: "En camino",
    },
    {
      code: ["G01L010V", "H020000V"],
      name: "En reparto",
    },
    {
      code: ["I010000V"],
      name: "Entregado",
    },
    {
      code: [
        "E240000R",
        "L03D360R",
        "L03D046R",
        "L03D082R",
        "L03D300R",
        "L03D320R",
        "L03DR40V",
        "L03I270R",
        "X170000V",
        "H01E100R",
        "H01E110R",
        "H01E130R",
        "H01E200R",
        "H01E220R",
        "H01E280R",
        "E321004V",
        "I01H230V",
        "X320000V",
        "Z190DF0R",
        "Z190DP0R",
        "Z190DR0R",
        "L03D270V",
        "Z190DD0R",
        "Z190000R",
        "H01E180R",
        "ADV0000V",
        "L020000V",
      ],
      name: "Devuelto",
    },
  ];

  const formatDate = (date) => {
    let formattedDate = new Date(date);

    if (formattedDate == "Invalid Date") {
      return "N/D";
    }

    return formattedDate
      .toLocaleDateString("es-Es", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(",", "");
  };

  const generatePod = (shipmentCode, pod) => {
    const linkSource = `data:image/jpeg;base64,${pod}`;
    const downloadLink = document.createElement("a");
    const fileName = `${shipmentCode}.jpg`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const fetchShipments = (
    shipmentCode,
    numRefClient,
    start,
    end,
    size,
    page,
    pagination
  ) => {
    setShipmentData((prevState) => ({
      ...prevState,
      status: pagination ? "paginating" : "loading",
      message: "Cargando...",
      statusCode: 0,
    }));

    instance
      .get(back.getListEnvios, {
        params: {
          shipmentCode: shipmentCode,
          numRefClient: numRefClient,
          startDate: start ? moment(start).format("DD-MM-YYYY") : null,
          endDate: end ? moment(end).format("DD-MM-YYYY") : null,
          size: size || 10,
          page: page - 1 || 0,
        },
      })
      .then(function (response) {
        let processedShipments = [];

        if (response.status !== 200) {
          setShipmentData({
            status: "loaded",
            data: processedShipments,
            message: "No se han encontrado datos.",
            statusCode: response.status,
          });
        }

        if (response.status === 200) {
          if (response.data.shipmentList?.data) {
            response.data.shipmentList.data.forEach(function (shipment, index) {
              shipment["key"] = index;
              processedShipments.push(shipment);
            });
          }

          setShipmentData({
            status: "loaded",
            data: processedShipments,
            statusCode: response.status,
            message: "",
            size: response.data.shipmentList.pageSize,
            page: response.data.shipmentList.pageNumber + 1,
            totalResults: response.data.shipmentList.totalResults,
            totalPages: response.data.shipmentList.totalPages,
          });

          setTelemetryInfo({
            warningsImpact: response.data.alertTotal.warningsImpactTotal,
            warningsOpen: response.data.alertTotal.warningsOpenTotal,
            warningsTemp: response.data.alertTotal.warningsTempTotal,
            statusCode: 200,
          });
        }
      })
      .catch(function (error) {
        setShipmentData((prevState) => ({
          ...prevState,
          status: "loaded",
          statusCode: 500,
          message: "Se ha producido un error, inténtelo de nuevo.",
        }));
      });
  };

  const fetchSingleShipment = async (shipmentCode) => {
    const url = back.getShipmentInfo;

    await instance
      .get(url, {
        params: {
          shipmentCode: shipmentCode,
        },
      })
      .then((response) => {
        const { data } = response;
        if (response.status !== 200) {
          setSingleShipmentData((prevState) => ({
            ...prevState,
            statusCode: response.status,
            status: "loaded",
            message: "No se han encontrado datos de envío.",
          }));

          setEventsInfo((prevState) => ({
            ...prevState,
            statusCode: response.status,
          }));
        }

        if (response.status === 200) {
          setSingleShipmentData({
            file: data.file,
            standAlone: data.standalone,
            statusCode: response.status,
            status: "loaded",
            telemetryTypeCode: data.telemetryTypeCode,
          });

          if (data.standalone) {
            if (!data.file) {
              if (data.alertTotal) {
                setTelemetryInfo((prevState) => ({
                  ...prevState,
                  warningsImpact: data.alertTotal.warningsImpactTotal,
                  warningsOpen: data.alertTotal.warningsOpenTotal,
                  warningsTemp: data.alertTotal.warningsTempTotal,
                  statusCode: response.status,
                }));
              }

              if (!data.alertTotal) {
                setTelemetryInfo((prevState) => ({
                  ...prevState,
                  warningsImpact: 0,
                  warningsOpen: 0,
                  warningsTemp: 0,
                  statusCode: response.status,
                }));
              }

              const singleShipmentTelemetryData =
                processSingleShipmentTelemetryData(data);
              setAlertsListInfo((prevState) => ({
                ...prevState,
                ...singleShipmentTelemetryData,
                statusCode: response.status,
              }));

              const singleShipmentChartsData =
                processSingleShipmentChartsData(data);
              setChartsInfo((prevState) => ({
                ...prevState,
                ...singleShipmentChartsData,
                statusCode: response.status,
              }));

              const singleShipmentEventsData =
                processSingleShipmentEvents(data);

              setEventsInfo((prevState) => ({
                ...prevState,
                ...singleShipmentEventsData,
                statusCode: 200,
              }));
            }
            const singleShipmentEventsData = processSingleShipmentEvents(data);

            setEventsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentEventsData,
              statusCode: 200,
            }));
          }

          if (!data.standalone) {
            if (data.alertTotal) {
              setTelemetryInfo((prevState) => ({
                ...prevState,
                warningsImpact: data.alertTotal.warningsImpactTotal,
                warningsOpen: data.alertTotal.warningsOpenTotal,
                warningsTemp: data.alertTotal.warningsTempTotal,
                statusCode: response.status,
              }));
            }

            if (!data.alertTotal) {
              setTelemetryInfo((prevState) => ({
                ...prevState,
                warningsImpact: 0,
                warningsOpen: 0,
                warningsTemp: 0,
                statusCode: response.status,
              }));
            }

            const singleShipmentTelemetryData =
              processSingleShipmentTelemetryData(data);
            setAlertsListInfo((prevState) => ({
              ...prevState,
              ...singleShipmentTelemetryData,
              statusCode: response.status,
            }));

            const singleShipmentChartsData =
              processSingleShipmentChartsData(data);
            setChartsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentChartsData,
              statusCode: response.status,
            }));

            const singleShipmentEventsData = processSingleShipmentEvents(data);

            setEventsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentEventsData,
              statusCode: 200,
            }));
          }
        }
      })
      .catch((error) => {
        setSingleShipmentData((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "Se ha producido un error, inténtelo de nuevo.",
        }));

        setEventsInfo((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "",
        }));

        setTelemetryInfo((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "No se han encontrado datos de alertas para este envío",
        }));
        console.log(error);
      });
  };

  const processSingleShipmentTelemetryData = (data) => {
    let alertsList = [];
    let calcMin = data.tempMin || -50;
    let calcMax = data.tempMax || 50;
    let tempRisk = (calcMax - calcMin) * 0.1;

    if (data.tempMin > 50) {
      calcMin = -(data.tempMin - 50);
    }

    if (data.tempMax > 50) {
      calcMax = -(data.tempMax - 50);
    }

    if (data.shipmentTelemetryList) {
      data.shipmentTelemetryList.forEach(function (v, i) {
        // if (v.warningImpact === true) {
        //   alertsList.push({
        //     class: "alert",
        //     type: "impacto",
        //     message: "El contenedor ha recibido un impacto.",
        //     color: "impacto",
        //     date: formatDate(v.sampleDate),
        //   });
        // }

        // if (v.warningOpen === true) {
        //   alertsList.push({
        //     class: "alert",
        //     type: "apertura",
        //     message: "Se ha abierto el contenedor.",
        //     date: formatDate(v.sampleDate),
        //   });
        // }

        if (v.temperature < calcMin || v.temperature > calcMax) {
          alertsList.push({
            class: "alert",
            type: "temperatura",
            message:
              "El contenedor está fuera de los rangos de temperatura establecidos.",
            date: formatDate(v.sampleDate),
          });
        } else if (
          v.temperature >= calcMax - tempRisk ||
          v.temperature <= calcMin + tempRisk
        ) {
          alertsList.push({
            class: "warning",
            type: "temperatura",
            message:
              "La temperatura está cerca de salirse de los rangos establecidos.",
            date: formatDate(v.sampleDate),
          });
        }
      });
    }

    return {
      tempMax: calcMax,
      tempMin: calcMin,
      alertsList: alertsList,
      configTempMax: data.tempMax,
      configTempMin: data.tempMin,
    };
  };

  const processSingleShipmentChartsData = (data) => {
    let calcMin = data.tempMin;
    let calcMax = data.tempMax;
    let chartData = [];

    if (data.tempMin > 50) {
      calcMin = -(data.tempMin - 50);
    }

    if (data.tempMax > 50) {
      calcMax = -(data.tempMax - 50);
    }

    if (data.shipmentTelemetryList) {
      data.shipmentTelemetryList.forEach(function (datum, i) {
        let temp = datum.temperature;
        let humidity = datum.humidity;

        if (datum.humidity > 100) {
          temp = -datum.temperature;
          humidity = datum.humidity - 100;
        }
        chartData.push({
          date: formatDate(datum.sampleDate),
          temp: temp,
          humidity: humidity,
        });
      });
    }

    return {
      telemetryInfo: chartData,
      tempMax: calcMax,
      tempMin: calcMin,
    };
  };

  const processSingleShipmentEvents = (data) => {
    const defaultSteps = [
      "Pre-registro",
      "En camino",
      "En reparto",
      "Entregado",
    ];
    const stepsTemp = defaultSteps.map((step) => ({ title: step }));
    let current = 0;
    let isReturned = false;

    const eventCodeToStepMap = baseSteps.reduce((acc, step) => {
      step.code.forEach((code) => {
        acc[code] = step.name;
      });
      return acc;
    }, {});

    const updateStep = (stepName, event) => {
      const stepIndex = defaultSteps.indexOf(stepName);
      if (stepIndex !== -1) {
        stepsTemp[stepIndex] = {
          title: stepName,
          description: formatDate(event.eventDate),
          location: event.eventLocation,
        };
        current = stepIndex;
      }
    };

    if (data.shipmentEventList) {
      data.shipmentEventList.forEach((shipmentEvent) => {
        const stepName = eventCodeToStepMap[shipmentEvent.eventCode];

        if (stepName === "Devuelto") {
          isReturned = true;
          stepsTemp[3] = {
            title: "Devuelto",
            description: formatDate(shipmentEvent.eventDate),
            location: shipmentEvent.eventLocation,
          };
        } else if (stepName) {
          updateStep(stepName, shipmentEvent);
        }
      });
    }

    if (isReturned) {
      current = 3;
    }

    return {
      steps: stepsTemp,
      current: current,
    };
  };

  const fetchShipmentPod = (shipmentCode) => {
    instance
      .get(back.fetchShipmentPod, {
        params: {
          shipmentCode: shipmentCode,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          return Modal.error({
            title: "No disponible",
            content:
              "La imagen de firma (POD) no está disponible para su descarga.",
          });
        }
        if (response.status === 200) {
          const { shipmentCode, pod } = response.data;
          return Modal.success({
            title: `POD ${shipmentCode}`,
            content: "La imagen de firma se ha generado con éxito.",
            onOk: () => generatePod(shipmentCode, pod),
            okText: "Descargar",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return Modal.error({
          title: "No disponible",
          content:
            "La imagen de firma (POD) no está disponible para su descarga.",
        });
      });
  };

  return {
    shipmentData,
    singleShipmentData,
    fetchShipments,
    fetchSingleShipment,
    telemetryInfo,
    alertsListInfo,
    chartsInfo,
    eventsInfo,
    fetchShipmentPod,
  };
}
